import React from "react";

import PropTypes from "prop-types";

import Geocode from "react-geocode";

import { ChildrenPropType } from "../../LoctionSorted.proptypes";
import { calculateDistance } from "../../../../utils/geo";

export const LOCATION_STATUS = {
  IDLE: 0,
  WAITING_FOR_USER_LOCATION: 1,
  CALCULATING_DIST: 2,
  FINISHED: 3,
  LOCATION_UNAVAILABLE: 4,
};

export default class GeoClosestLocation extends React.Component {
  constructor(props) {
    super(props);

    Geocode.setApiKey(process.env.GOOGLE_MAPS_API_KEY);

    if (process.env.NODE_ENV === "development") {
      Geocode.enableDebug();
    }

    this.state = {
      loading: false,
      locationStatus: LOCATION_STATUS.IDLE,
      locationsWithDistance: props.locations.map((l) => ({
        ...l,
        fullAddress: GeoClosestLocation.getFullAddress(l),
      })),
    };

    this.hasUsersLocation = this.hasUsersLocation.bind(this);
    this.doesNotHaveUsersLocation = this.doesNotHaveUsersLocation.bind(this);
  }

  componentDidMount() {
    const { start } = this.props;
    if (start) {
      this.getUsersLocation();
    }
  }

  componentDidUpdate(prevProps) {
    const { start: oldStart } = prevProps;
    const { start: newStart } = this.props;
    const { locationStatus } = this.state;

    if (newStart && !oldStart && locationStatus === LOCATION_STATUS.IDLE) {
      this.getUsersLocation();
    }
  }

  getUsersLocation() {
    if ("geolocation" in navigator) {
      this.setState({
        loading: true,
        locationStatus: LOCATION_STATUS.WAITING_FOR_USER_LOCATION,
      });
      navigator.geolocation.getCurrentPosition(
        this.hasUsersLocation,
        this.doesNotHaveUsersLocation
      );
    } else {
      this.setState({
        loading: false,
        locationStatus: LOCATION_STATUS.LOCATION_UNAVAILABLE,
      });
    }
  }

  static getFullAddress(location) {
    return `${location.street}, ${location.city}, ${location.state} ${location.zip}`;
  }

  doesNotHaveUsersLocation() {
    this.setState({
      loading: false,
      locationStatus: LOCATION_STATUS.LOCATION_UNAVAILABLE,
    });
  }

  async hasUsersLocation(position) {
    const { locations, distanceUnit } = this.props;

    this.setState({
      loading: true,
      locationStatus: LOCATION_STATUS.CALCULATING_DIST,
    });

    const userLat = position.coords.latitude;
    const userLong = position.coords.longitude;

    const locationsWithDistance = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const location of locations) {
      const fullAddress = GeoClosestLocation.getFullAddress(location);
      let distance = -1;

      try {
        // eslint-disable-next-line no-await-in-loop
        const results = await Geocode.fromAddress(fullAddress);

        if (results) {
          const { lat, lng } = results.results[0].geometry.location;
          distance = calculateDistance(
            lat,
            lng,
            userLat,
            userLong,
            distanceUnit
          );
        }
      } catch (error) {
        // pass
      }

      locationsWithDistance.push({
        ...location,
        fullAddress,
        distance,
        distanceUnit,
      });
    }

    locationsWithDistance.sort((a, b) => {
      if (a.distance === -1) return 1;
      if (b.distance === -1) return -1;
      return a.distance > b.distance ? 1 : -1;
    });

    this.setState({
      loading: false,
      locationStatus: LOCATION_STATUS.FINISHED,
      locationsWithDistance,
    });
  }

  render() {
    const { loading, locationStatus, locationsWithDistance } = this.state;
    const { children } = this.props;

    return children({
      state: locationStatus,
      locations: locationsWithDistance,
      loading,
    });
  }
}

GeoClosestLocation.propTypes = {
  start: PropTypes.bool,
  distanceUnit: PropTypes.oneOf(["M", "K"]),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
    })
  ),
  children: ChildrenPropType.isRequired,
};

GeoClosestLocation.defaultProps = {
  start: false,
  distanceUnit: "M",
  locations: [],
};
