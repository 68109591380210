import React from "react";

import PropTypes from "prop-types";

import {
  parse,
  isBefore,
  isAfter,
  isEqual,
  isWithinInterval,
  differenceInMinutes,
  getISODay,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import isNil from "lodash/isNil";

import { HourOfOperationPropType } from "../../LoctionSorted.proptypes";

const OPEN_STATUS = "open";
const CLOSED_STATUS = "closed";
const CLOSING_SOON_STATUS = "closing_soon";

const propTypes = {
  hours: PropTypes.arrayOf(HourOfOperationPropType).isRequired,
  timezone: PropTypes.string,
};

const defaultProps = {
  timezone: null,
};

/**
 * Returns null if it's not today or no timezone is set, else Open or Closed.
 * Inspired on https://stackoverflow.com/a/59492313
 *
 * Assumes all hours[i].day are the same
 */
export default function CurrentlyOpenLabel({ hours, timezone }) {
  // Can't be totally sure without a timezone,
  // so don't do this if we don't have one.
  if (!timezone || hours.length === 0) {
    return null;
  }

  // If weekday isn't today.
  const now = utcToZonedTime(new Date(), timezone);
  const weekDayIso = getISODay(now);

  if (weekDayIso !== hours[0].day) {
    return null;
  }

  const allHourStatus = hours.map((hour) => {
    if (!timezone || timezone === "UTC") {
      return null;
    }
    const { open, close } = hour;
    if (isNil(open) || isNil(close)) {
      return null;
    }

    const openTimeString = open.toString().padStart(4, "0");
    const openMoment = utcToZonedTime(
      parse(openTimeString, "HHmm", new Date()),
      timezone
    );

    const closeTimeString = close.toString().padStart(4, "0");
    const closeMoment = utcToZonedTime(
      parse(closeTimeString, "HHmm", new Date()),
      timezone
    );

    // Make sure the days are the same
    // TODO ensure that the days are the same, unless overnight hours
    // if(!openMoment.isSame(now, 'day')) {
    //   openMoment.set('day', now.day());
    //   closeMoment.set('day', now.day());
    // }

    let hourStatus = CLOSED_STATUS;
    if (isBefore(closeMoment, openMoment) || isEqual(openMoment, closeMoment)) {
      // Handle ranges that span over midnight
      if (isAfter(now, openMoment)) {
        hourStatus = OPEN_STATUS;
      }
    } else if (
      isWithinInterval(now, { start: openMoment, end: closeMoment }) &&
      isBefore(now, closeMoment)
    ) {
      // Normal range check using an inclusive start time and exclusive end time
      hourStatus = OPEN_STATUS;
      if (differenceInMinutes(closeMoment, now) <= 30) {
        hourStatus = CLOSING_SOON_STATUS;
      }
    }
    return hourStatus;
  });

  if (allHourStatus.includes(OPEN_STATUS)) {
    return (
      <span className="hours-open-label" style={{ fontWeight: "bold" }}>
        Open
      </span>
    );
  }

  if (allHourStatus.includes(CLOSING_SOON_STATUS)) {
    return (
      <span className="hours-closing-soon-label" style={{ fontWeight: "bold" }}>
        Closing soon
      </span>
    );
  }

  if (allHourStatus.includes(CLOSED_STATUS)) {
    return <span className="hours-closed-label">Closed</span>;
  }

  return null;
}

CurrentlyOpenLabel.propTypes = propTypes;
CurrentlyOpenLabel.defaultProps = defaultProps;
