import React from "react";

import PropTypes from "prop-types";
import { Header, Icon, Card, Button } from "semantic-ui-react";

import Map from "../Map";
import OutboundLink from "../OutboundLink";
import { AddressPropType } from "../../LoctionSorted.proptypes";
import ContactElement from "../ContactElement";
import HoursOfOperation from "../HoursOfOperation";
import IconElement from "../IconElement";

import "./location-as-card.css";

export default class Location extends React.PureComponent {
  getFormattedAddress() {
    const { street, locality, country } = this.createAddressComponents();
    return (
      (street ? `${street}, ${locality}` : locality) +
      (country ? ` ${country}` : "")
    );
  }

  createAddressComponents() {
    const { address } = this.props;
    const { street, city, state, zip, country } = address;
    const locality = `${city}, ${state} ${zip}`;
    return { street, locality, country };
  }

  createMapLink(type) {
    const { businessName } = this.props;
    const formattedAddress = this.getFormattedAddress();

    // Link constants
    const linkBase = "https://www.google.com/maps/search/?api=1&query=";
    const embedBase = "https://maps.google.com/maps?q=";
    const embedTail = "&t=&z=13&ie=UTF8&iwloc=&output=embed";

    // Address query
    const query = encodeURIComponent(
      businessName ? `${businessName} ${formattedAddress}` : formattedAddress
    );

    // Construct link
    if (type === "link") return `${linkBase}${query}`;

    return `${embedBase}${query}${embedTail}`;
  }

  generateHumanReadableDistance() {
    const { distance, distanceUnit } = this.props;
    return (
      distance &&
      `${distance.toFixed(2)} ${distanceUnit === "M" ? " miles" : "km"} away`
    );
  }

  renderAddress() {
    const {
      as,
      phone,
      email,
      link,
      businessName,
      distance,
      name,
      hours,
      timezone,
      showBusinessName,
      showAddress,
      iconPosition,
      showIcon,
      className,
      buttonColor,
      fluidButton,
      buttonText,
      buttonSize,
      inline,
      actions,
    } = this.props;

    if (!showAddress) return "";

    // Button values
    const additionalButtonProps = { buttonColor, fluidButton, buttonSize };

    // Address values
    const { street, locality, country } = this.createAddressComponents();

    if (as === "card") {
      const linkProps = {};
      linkProps.href = this.createMapLink("link");
      linkProps.target = "_blank";
      linkProps.rel = "noopener noreferrer";

      return (
        <Card fluid className="location-as-card">
          <Card.Content>
            <Card.Header>{name || street}</Card.Header>
            <Card.Description className="location-as-card-description">
              <div>
                <Icon name="map" />
              </div>
              <div>
                {street}
                <br />
                {locality}
                {country && `, ${country}`}
              </div>
            </Card.Description>
            {phone && (
              <Card.Description className="location-as-card-description">
                <div>
                  <Icon name="phone" />
                </div>
                <div>{phone}</div>
              </Card.Description>
            )}
            {email && (
              <Card.Description className="location-as-card-description">
                <div>
                  <Icon name="mail" />
                </div>
                <div>{email}</div>
              </Card.Description>
            )}
            {hours && (
              <Card.Description className="location-as-card-description">
                <div>
                  <Icon name="calendar outline" />
                </div>
                <div>
                  <HoursOfOperation
                    hours={hours}
                    timezone={timezone}
                    displayOption="dailyWithPopup"
                  />
                </div>
              </Card.Description>
            )}
            {distance && (
              <Card.Description className="location-as-card-description">
                <div>
                  <Icon name="world" />
                </div>
                <div>{this.generateHumanReadableDistance()}</div>
              </Card.Description>
            )}
          </Card.Content>
          <Card.Content style={{ display: "flex", justifyContent: "center" }}>
            <Button
              secondary
              as={OutboundLink}
              to={linkProps.href}
              icon="map"
              content="Directions"
            />
            {phone && (
              <Button
                secondary
                as={OutboundLink}
                to={`tel:${phone}`}
                icon="phone"
                content="Call"
              />
            )}
            {email && (
              <Button
                secondary
                as={OutboundLink}
                to={`mailto:${email}`}
                icon="mail"
                content="Email"
              />
            )}
            {actions.map(({ icon, url, label }) => (
              <Button
                secondary
                as={OutboundLink}
                to={url}
                icon={icon}
                content={label}
              />
            ))}
          </Card.Content>
        </Card>
      );
    }

    if (as === "text") {
      const linkProps = {};
      linkProps.href = this.createMapLink("link");
      linkProps.target = "_blank";
      linkProps.rel = "noopener noreferrer";

      const plainHeader = (
        <div>
          {businessName && showBusinessName && (
            <>
              <strong>{businessName}</strong>
              <br />
            </>
          )}

          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <a as="a" {...linkProps}>
            <span>
              {street}
              {!inline && <br />}
              {locality}
              {country && `, ${country}`}
            </span>
          </a>

          {phone && (
            <>
              <br />
              <a href={`tel:${phone}`}>{phone}</a>
            </>
          )}

          {email && (
            <>
              <br />
              <a href={`mailto:${email}`}>{email}</a>
            </>
          )}

          {link && (
            <>
              <br />
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                <Icon name={link.icon} />
                {link.title}
              </a>
            </>
          )}

          {distance && (
            <>
              <br />
              <p>{this.generateHumanReadableDistance()}</p>
            </>
          )}
        </div>
      );

      return showIcon ? (
        <IconElement
          iconType="map marker alternate"
          iconPosition={iconPosition}
          element={plainHeader}
        />
      ) : (
        plainHeader
      );
    }

    // If not displaying the address, display as a button
    return (
      <ContactElement
        contact={buttonText}
        link={this.createMapLink("link")}
        iconType="map marker alternate"
        as={buttonText === "" ? "icon" : "button"}
        className={className}
        content={buttonText}
        {...additionalButtonProps}
      />
    );
  }

  renderHeader() {
    const { header } = this.props;

    return <>{header && <Header as="h2">{header}</Header>}</>;
  }

  renderMap() {
    const { address, businessName } = this.props;
    return (
      <div style={{ marginTop: "1em" }}>
        <Map address={address} businessName={businessName} />
      </div>
    );
  }

  render() {
    const { showMap, header, showAddress = true } = this.props;

    return (
      <>
        {header !== "" && this.renderHeader()}
        {showAddress && this.renderAddress()}
        {showMap && this.renderMap()}
      </>
    );
  }
}

Location.propTypes = {
  address: AddressPropType.isRequired,
  as: PropTypes.oneOf(["button", "text", "icon"]),
  businessName: PropTypes.string,
  showBusinessName: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
  }),
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number.isRequired,
      open: PropTypes.number.isRequired,
      close: PropTypes.number.isRequired,
      label: PropTypes.string,
    })
  ),
  timezone: PropTypes.string,
  distance: PropTypes.number,
  distanceUnit: PropTypes.oneOf(["M", "K"]),
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonText: PropTypes.string,
  fluidButton: PropTypes.bool,
  header: PropTypes.string,
  iconPosition: PropTypes.string,
  showAddress: PropTypes.bool,
  showIcon: PropTypes.bool,
  showMap: PropTypes.bool,
  inline: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.object),
};

Location.defaultProps = {
  as: "text",
  businessName: "",
  showBusinessName: true,
  name: "",
  className: "",
  phone: null,
  email: null,
  link: null,
  hours: null,
  distance: null,
  timezone: "",
  distanceUnit: "M",
  buttonColor: "default",
  buttonSize: "large",
  buttonText: "",
  fluidButton: false,
  header: "",
  iconPosition: "left",
  showAddress: true,
  showIcon: false,
  showMap: false,
  inline: false,
  actions: [],
};

Location.propOptions = {
  buttonColor: ["primary", "secondary", "default"],
  header: ["Location", "Our Location", ""],
  iconPosition: ["left", "right", "top", "bottom"],
};

Location.dataPropsMap = {
  address: ["business", "primary_location", "address"], // check this one
  businessName: ["business", "primary_location", "name"],
};
