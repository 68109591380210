import React, { Suspense } from "react";

import PropTypes from "prop-types";
import { Placeholder } from "semantic-ui-react";

import { isServerSideRendering } from "../../../../utils/gatsby";

const LazyEmbed = React.lazy(() =>
  import("semantic-ui-react/dist/commonjs/modules/Embed")
);

export default class Map extends React.Component {
  getFormattedAddress() {
    const { street, locality } = this.createAddressComponents();

    return `${street}, ${locality}`;
  }

  createAddressComponents() {
    const { address } = this.props;
    const { street, city, state, zip, country } = address;

    const locality = `${city}, ${state} ${zip}`;

    return { street, locality, country };
  }

  createMapLink() {
    const { businessName } = this.props;
    const formattedAddress = this.getFormattedAddress();

    // Link constants
    const embedBase = `https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_MAPS_API_KEY}&q=`;

    // Address query
    const query = encodeURIComponent(
      businessName ? `${businessName} ${formattedAddress}` : formattedAddress
    );

    return `${embedBase}${query}`;
  }

  render() {
    const isSSR = isServerSideRendering();
    return (
      <>
        {!isSSR && (
          <Suspense
            fallback={
              <Placeholder className="map">
                <Placeholder.Image />
              </Placeholder>
            }
          >
            <LazyEmbed url={this.createMapLink()} active className="map" />
          </Suspense>
        )}
      </>
    );
  }
}

Map.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    country: PropTypes.string,
    street: PropTypes.string,
  }).isRequired,
  businessName: PropTypes.string,
};

Map.defaultProps = {
  businessName: "",
};
