import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import './IconElement.css';

const horizontalAlignment = {
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
};

const verticalAlignment = {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  display: 'inline-flex',
  flexDirection: 'column',
};

export default class IconElement extends React.PureComponent {
  render() {
    const { iconType, iconSize, iconPosition, element, className, onClick } = this.props;
    const horizontal = iconPosition === 'left' || iconPosition === 'right';
    const elementFirst = iconPosition === 'right' || iconPosition === 'bottom';

    return (
      <div
        onClick={onClick}
        className={`ie-base-class ${horizontal && 'ie-horiz'} ${className}`}
        style={horizontal ? horizontalAlignment : verticalAlignment}
        role="button"
        onKeyDown={onClick}
        tabIndex={-1}
      >
        {elementFirst && element}
        <Icon name={iconType} size={iconSize} />
        {!elementFirst && element}
      </div>
    );
  }
}

IconElement.propTypes = {
  className: PropTypes.string,
  element: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  iconSize: PropTypes.oneOf(['big', 'large', 'huge']),
  iconType: PropTypes.string,
  onClick: PropTypes.func,
};

IconElement.defaultProps = {
  className: '',
  element: <h2>Sample Element</h2>,
  iconPosition: 'left',
  iconSize: 'large',
  iconType: 'phone',
  onClick: null,
};
