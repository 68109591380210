import React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import { ChildrenPropType } from "../../LoctionSorted.proptypes";
import IconElement from "../IconElement";

const propTypes = {
  iconAs: PropTypes.string,
  children: ChildrenPropType.isRequired,
};

const defaultProps = {
  iconAs: "icon",
};

export default function HoursOfOperationModal(props) {
  const { iconAs, children } = props;
  return (
    <Modal
      className="hoo-modal"
      dimmer="inverted"
      trigger={
        iconAs === "icon" ? (
          <button type="button" className="hoo-modal-trigger">
            <IconElement
              iconType="clock"
              element={<span>See Hours</span>}
              iconPosition="left"
            />
          </button>
        ) : (
          <Button icon="clock" secondary />
        )
      }
    >
      <Modal.Header>
        <div>Hours Of Operation</div>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
}

HoursOfOperationModal.propTypes = propTypes;
HoursOfOperationModal.defaultProps = defaultProps;
