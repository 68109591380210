import React from 'react';

import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { NO_LABEL } from './utils';

import './HoursOfOperationLabeled.css';

const propTypes = {
  isHorizontal: PropTypes.bool,
  animated: PropTypes.bool,
  celled: PropTypes.bool,
  inlineHours: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  isHorizontal: false,
  animated: false,
  celled: false,
  inlineHours: false,
};

export default function HoursOfOperationLabeled({
  data,
  isHorizontal,
  animated,
  celled,
  inlineHours,
}) {
  return (
    <List horizontal={isHorizontal} animated={animated} celled={celled} relaxed>
      {data.map((labelData) => {
        const mainLabel = labelData[0];
        const hoursData = labelData[1];

        const mainList = (
          <List.List>
            {hoursData.map(({ secondaryLabel, hoursValue }, idx) => (
              <List.Item
                key={secondaryLabel}
                style={
                  !!secondaryLabel && idx !== 0 ? { marginTop: '1em' } : { marginTop: '0.25em' }
                }
              >
                <List.Content>
                  <List.Header as="h6">
                    {secondaryLabel}
                    {inlineHours && `: ${hoursValue.join(' | ')}`}
                  </List.Header>
                  {!inlineHours && (
                    <List.Description>
                      <ul className="unstyled-list">
                        {hoursValue.map((hour) => (
                          <li key={hour}>{hour}</li>
                        ))}
                      </ul>
                    </List.Description>
                  )}
                </List.Content>
              </List.Item>
            ))}
          </List.List>
        );

        return (
          <List.Item>
            <List.Content>
              {mainLabel !== NO_LABEL && (
                <List.Header as="header" className="h5">
                  {mainLabel}
                </List.Header>
              )}
              {mainList}
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
}

HoursOfOperationLabeled.propTypes = propTypes;
HoursOfOperationLabeled.defaultProps = defaultProps;
