import React from "react";

import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";

import Event from "../Event";
import IconElement from "../IconElement";

export default class ContactElement extends React.Component {
  renderLink() {
    const {
      contact,
      link,
      iconType,
      as,
      iconPosition,
      buttonColor,
      fluidButton,
      buttonSize,
    } = this.props;

    // Button use case
    const iconProps = iconType === "none" ? {} : { icon: iconType };
    const contentProps = as === "icon" ? {} : { content: contact };

    const isPrimary = buttonColor === "primary";
    const buttonColorProps = isPrimary
      ? { primary: true }
      : { secondary: true };

    if (as === "text") {
      const header = (
        <a href={link}>
          <p>{contact}</p>
        </a>
      );

      const textContent =
        iconType === "none" || iconType === "" ? (
          header
        ) : (
          <IconElement
            iconType={iconType}
            element={header}
            iconPosition={iconPosition}
          />
        );

      return (
        <Event
          event={{
            category: "Contact",
            action: "click",
            label: link,
          }}
          eventType="click"
        >
          {textContent}
        </Event>
      );
    }

    return (
      <Event
        event={{
          category: "Contact",
          action: "click",
          label: link,
        }}
        eventType="click"
      >
        <Button
          fluid={fluidButton}
          size={buttonSize}
          as="a"
          href={link}
          {...buttonColorProps}
          {...contentProps}
          {...iconProps}
        />
      </Event>
    );
  }

  renderHeader() {
    const { header } = this.props;

    return <Header as="h2">{header}</Header>;
  }

  render() {
    const { className, header } = this.props;

    return header !== "" ? (
      <div className={className}>
        {this.renderHeader()}
        {this.renderLink()}
      </div>
    ) : (
      <div className={className}>{this.renderLink()}</div>
    );
  }
}

ContactElement.propTypes = {
  contact: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  as: PropTypes.oneOf(["text", "button", "icon"]),
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  className: PropTypes.string,
  fluidButton: PropTypes.bool,
  header: PropTypes.string,
  iconPosition: PropTypes.string,
  iconType: PropTypes.string,
};

ContactElement.defaultProps = {
  as: "text",
  buttonColor: "primary",
  buttonSize: "large",
  className: "",
  fluidButton: false,
  header: "",
  iconPosition: "left",
  iconType: "none",
};
