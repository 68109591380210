import { getDay, format, startOfDay, isEqual } from "date-fns";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";

import DateUtils from "../../../../utils/date/DateUtils";

const CLOSED_LABEL = "Closed";
const OPEN_ALL_DAY_LABEL = "Open 24 Hours";
const MOMENT_SUNDAY = 0;
const FISHERMAN_SUNDAY = 7;
export const NO_LABEL = "NO_LABEL";

export const HOURS_LABELS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};
const DAY_LABELS = new Set(
  Object.values(HOURS_LABELS).map((l) => l.toLowerCase())
);

export function getHourLabel(hour) {
  // Returns empty string if the label is a day label. Otherwise, returns hour.label
  const { label = "" } = hour;
  const hourLabel = label.toLowerCase();
  return DAY_LABELS.has(hourLabel) ? "" : label;
}

function getFormattedTimeMoment(timeMoment) {
  if (isEqual(timeMoment, startOfDay(timeMoment))) {
    return "Midnight";
  }
  return format(timeMoment, "h:mma");
}

function isClosed(open, close) {
  // TODO: change name or fix because this is not closed
  return !isNumber(open) || !isNumber(close);
}

function isOpenAllDay(hours, day) {
  // Get all opening hours for day
  const hoursForDay = hours.reduce((acc, opHour) => {
    if (opHour.day === day - 1) {
      if (opHour.close <= opHour.open) {
        // it closes after midnight
        return [...acc, { open: 0, close: opHour.close }];
      }
    }

    if (opHour.day === day) {
      return [...acc, { open: opHour.open, close: opHour.close }];
    }

    return [...acc];
  }, []);

  // Ensure all hours are sorted by open key
  hoursForDay.sort((a, b) => a.open - b.open);

  // Check that hours for day covers the whole day without any gap
  if (hoursForDay.length === 1) {
    return (
      hoursForDay[0].open === 0 &&
      (hoursForDay[0].close === 0 || hoursForDay[0].close === 2400)
    );
  }

  let openAllDay = hoursForDay[0].open === 0;
  for (let i = 1; i < hoursForDay.length; i += 1) {
    // Check that every split hour is attached to the next
    openAllDay = openAllDay && hoursForDay[i - 1].close >= hoursForDay[i].open;

    if (i === hoursForDay.length - 1) {
      // Check that the last hour closes at midnight or after it
      openAllDay =
        openAllDay &&
        (hoursForDay[i].close <= hoursForDay[i].open ||
          hoursForDay[i].close === 0 ||
          hoursForDay[0].close === 2400);
    }
  }

  return openAllDay;
}

function createTimeValue(hours, hourDay) {
  const { open, close, day } = hourDay;
  if (isClosed(open, close)) return CLOSED_LABEL;
  if (isOpenAllDay(hours, day)) return OPEN_ALL_DAY_LABEL;

  const openTime = DateUtils.parseHourInt(open);
  const openString = getFormattedTimeMoment(openTime);
  const closeTime = DateUtils.parseHourInt(close);
  const closeString = getFormattedTimeMoment(closeTime);
  return `${openString} - ${closeString}`;
}

function convertMomentDay(momentDay) {
  const todayInt = getDay(momentDay);
  return todayInt === MOMENT_SUNDAY ? FISHERMAN_SUNDAY : todayInt;
}

export function setStartDayInGroup(hourGroups, dayInt) {
  const firstTodayIdx = hourGroups.findIndex(
    (hourGroup) => hourGroup.startDay <= dayInt && dayInt <= hourGroup.endDay
  );
  if (firstTodayIdx >= 0) {
    return [
      ...hourGroups.slice(firstTodayIdx),
      ...hourGroups.slice(0, firstTodayIdx),
    ];
  }
  return hourGroups;
}

export function setFirstHoursInGroup(hourGroups, timezone) {
  const today = DateUtils.getTimezoneAwareNow(timezone);
  const todayInt = convertMomentDay(today);
  return setStartDayInGroup(hourGroups, todayInt);
}

export function getHourGroups(hourGroups, timezone, startWithToday, startDay) {
  const todayFirstInHoursGroup = setFirstHoursInGroup(hourGroups, timezone);
  const finalHourGroups = startWithToday
    ? todayFirstInHoursGroup
    : setStartDayInGroup(hourGroups, startDay);
  return [todayFirstInHoursGroup, finalHourGroups];
}

/**
 * Groups hours by day. Each object in the array will have a hoursValue array
 * containing all hour strings for the same day.
 *
 * Returns an array of objects with keys:
 * - dayLabel: str
 * - hoursValue: [str]
 * - hours: [{open, close, day, label}]
 * - startDay: int
 * - endDay: int
 */
export function groupByDay(hours, getLabel) {
  const hoursMap = {};
  hours.forEach((hour) => {
    const { day, open, close } = hour;
    const key = day;

    if (!hoursMap[key]) {
      hoursMap[key] = {
        dayLabel: getLabel(day),
        hoursValue: [],
        hours: [],
        startDay: day,
        endDay: day,
      };
    }

    hoursMap[key] = {
      ...hoursMap[key],
      hoursValue: [
        ...hoursMap[key].hoursValue,
        createTimeValue(hours, { open, close, day }),
      ],
      hours: [...hoursMap[key].hours, hour],
    };
  });

  return Object.values(hoursMap);
}

function hourStr({ open, close, label }) {
  return `${open} - ${close} - ${label}`;
}

/**
 * Groups consecutive days that have the same hour string.
 *
 * Returns an array of objects with keys:
 * - dayLabel: str
 * - hoursValue: [str]
 * - hours: [{open, close, day, label}]
 * - startDay: int
 * - endDay: int
 */
export function groupByDayAndTime(hours, getLabel) {
  const hoursGroupedByDay = groupByDay(hours, getLabel);

  // Group consecutive days with the same list of hourString
  // Create a list of objects, where each object has 3 values: hoursValue, start day, end day
  const orderedDays = hoursGroupedByDay.sort(
    (aDay, bDay) => aDay.startDay - bDay.startDay
  );

  const groups = [];
  let startDayForGroup = orderedDays[0];
  let previousDay = orderedDays[0];

  let currentDayHourStr = null;
  let previousDayHourStr = null;

  orderedDays.forEach((currentDay, currentIndex) => {
    if (currentIndex !== 0) {
      // The idea is to compare day string by day string and while they
      // are the same they will be grouped together. Every group is split
      // when a day string is different to the previous one or some edge cases
      // when we are checking the last day of the week
      previousDay = orderedDays[currentIndex - 1];
      currentDayHourStr = currentDay.hours
        .map((hour) => hourStr(hour))
        .join(",");
      previousDayHourStr = previousDay.hours
        .map((hour) => hourStr(hour))
        .join(",");

      if (
        (currentDayHourStr !== previousDayHourStr ||
          previousDay.startDay + 1 !== currentDay.startDay) &&
        currentIndex !== orderedDays.length - 1
      ) {
        groups.push({
          hoursValue: previousDay.hoursValue,
          hours: previousDay.hours,
          startDay: startDayForGroup.startDay,
          endDay: previousDay.endDay,
        });
        startDayForGroup = currentDay;
      } else if (
        currentDayHourStr === previousDayHourStr &&
        currentIndex === orderedDays.length - 1
      ) {
        groups.push({
          hoursValue: previousDay.hoursValue,
          hours: previousDay.hours,
          startDay: startDayForGroup.startDay,
          endDay: currentDay.endDay,
        });
      } else if (
        (currentDayHourStr !== previousDayHourStr ||
          previousDay.startDay + 1 !== currentDay.startDay) &&
        currentIndex === orderedDays.length - 1
      ) {
        groups.push({
          hoursValue: previousDay.hoursValue,
          hours: previousDay.hours,
          startDay: startDayForGroup.startDay,
          endDay: previousDay.endDay,
        });
        groups.push({
          hoursValue: currentDay.hoursValue,
          hours: currentDay.hours,
          startDay: currentDay.startDay,
          endDay: currentDay.endDay,
        });
      }
    }
    if (orderedDays.length === 1) {
      groups.push({
        hoursValue: currentDay.hoursValue,
        hours: currentDay.hours,
        startDay: currentDay.startDay,
        endDay: currentDay.startDay,
      });
    }
  });
  return groups.map(({ hoursValue, hours: allHours, startDay, endDay }) => {
    let hoursGroupLabel;

    if (startDay === endDay) {
      hoursGroupLabel = getLabel(startDay);
    } else {
      hoursGroupLabel = getLabel(startDay, endDay);
    }

    return {
      dayLabel: hoursGroupLabel,
      hoursValue,
      hours: allHours,
      startDay,
      endDay,
    };
  });
}

/**
 * Groups hours in a two-level hierarchy.
 * First hierarchy is the label (i.e: lunch, breakfast, takeaway)
 * Second hierarchy is the dayLabel (label that comes from groupByDay or
 * groupByDayAndTime)
 *
 * Returns an array of arrays. Each subarray has 2 values. The first is the
 * main label and the second is an array of objects:
 * [
 *   [mainLabel, [{
 *     - secondaryLabel: str
 *     - hoursValue: [str]
 *     - hours: [{open, close, day, label}]
 *     - startDay: int
 *     - endDay: int
 *   }]
 * ]
 *
 */
export function groupByLabel(
  hours,
  getLabel,
  displayOption,
  startWithToday,
  timezone,
  dayToStartWith
) {
  // First create a map with labels as keys and array of hours as value
  const labelToHours = {};
  hours.forEach((hour) => {
    const lbl = getHourLabel(hour) || NO_LABEL;

    if (!labelToHours[lbl]) {
      labelToHours[lbl] = [];
    }

    labelToHours[lbl] = [...labelToHours[lbl], hour];
  });

  // Finally, create a new map with labels as keys and a groupByDay or
  // groupByDayAndTime according to displayOption
  const labelToGroup = [];

  // leave label NO_LABEL first in the array
  const labels = Object.keys(labelToHours).sort((lbl1, lbl2) => {
    if (lbl1 === NO_LABEL) return -1;
    if (lbl2 === NO_LABEL) return 1;
    return 0;
  });

  let todaysHourOfOperation = null;

  labels.forEach((label) => {
    const groupedHourGroups =
      displayOption === "grouped"
        ? groupByDayAndTime(labelToHours[label], getLabel)
        : groupByDay(labelToHours[label], getLabel);

    const [todayFirstInHoursGroup, hourGroups] = getHourGroups(
      groupedHourGroups,
      timezone,
      startWithToday,
      dayToStartWith
    );

    if (!todaysHourOfOperation) {
      [todaysHourOfOperation] = todayFirstInHoursGroup;
      todaysHourOfOperation.mainLabel = label;
    }
    const finalHourGroups = hourGroups.map(
      ({ hoursValue, hours: groupHours, startDay, endDay, dayLabel }) => ({
        hoursValue,
        hours: groupHours,
        startDay,
        endDay,
        secondaryLabel: dayLabel,
      })
    );

    labelToGroup.push([label, finalHourGroups]);
  });

  return [labelToGroup, todaysHourOfOperation];
}

/**
 * Groups hours in a two-level hierarchy.
 * First hierarchy is the dayLabel (i.e: lunch, breakfast, takeaway)
 * Second hierarchy is the dayLabel (label that comes from groupByDay or
 * groupByDayAndTime)
 *
 * Returns an object with labels as key and each value is an array of
 * objects:
 * [
 *   [mainLabel, [{
 *     - secondaryLabel: str
 *     - hoursValue: [str]
 *     - hours: [{open, close, day, label}]
 *     - startDay: int
 *     - endDay: int
 *   }]
 * ]
 *
 */
export function groupByDayLabel(
  hours,
  getLabel,
  displayOption,
  startWithToday,
  timezone,
  startDay
) {
  const groupedHourGroups =
    displayOption === "grouped"
      ? groupByDayAndTime(hours, getLabel)
      : groupByDay(hours, getLabel);

  const [todayFirstInHoursGroup, hourGroups] = getHourGroups(
    groupedHourGroups,
    timezone,
    startWithToday,
    startDay
  );
  const todaysHourOfOperation = todayFirstInHoursGroup[0];

  // Set dayLabel as the main label and for each hour in the group, there will
  // be an object with the hour label as the dayLabel, to share the same
  // format that the other way of grouping
  const grouping = [];
  hourGroups.forEach((group) => {
    const { dayLabel } = group;
    const groupHours = group.hours.map((hour, idx) => ({
      secondaryLabel: getHourLabel(hour),
      hoursValue: [group.hoursValue[idx]],
      hours: [hour],
      startDay: hour.day,
      endDay: hour.day,
    }));

    grouping.push([dayLabel, groupHours]);
  });
  return [grouping, todaysHourOfOperation];
}

export function allDayHoursAreUndefined(hours) {
  return hours.every(({ open, close }) => isNil(open) || isNil(close));
}
