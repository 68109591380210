import React from "react";

import PropTypes from "prop-types";
import { Container, Input, Header } from "semantic-ui-react";

import { AsPropType } from "../LoctionSorted.proptypes";
// import SortByDistanceButton from './SortByDistanceButton';

const propTypes = {
  searchHeader: PropTypes.string.isRequired,
  searchHeaderAs: AsPropType,
  searchPlaceholder: PropTypes.string,
  distanceLoading: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  locationSortingStatus: PropTypes.number.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

const defaultProps = {
  distanceLoading: false,
  searchHeaderAs: "h2",
  searchPlaceholder: "123 Main Street, Boston MA",
  searchQuery: "",
};

export default class SearchLocations extends React.PureComponent {
  render() {
    const {
      searchHeader,
      searchHeaderAs,
      onSearchChange,
      searchQuery,
      searchPlaceholder,
      locationSortingStatus,
      onSort,
      distanceLoading,
    } = this.props;

    return (
      <Container className="fm-search-locations">
        <Header as={searchHeaderAs}>{searchHeader}</Header>
        <div>
          <Input
            fluid
            onChange={onSearchChange}
            value={searchQuery}
            action={{ content: "Search", primary: true }}
            placeholder={searchPlaceholder}
          />
          {/* <SortByDistanceButton
            locationSortingStatus={locationSortingStatus}
            onSort={onSort}
            loading={distanceLoading}
          /> */}
        </div>
      </Container>
    );
  }
}

SearchLocations.propTypes = propTypes;
SearchLocations.defaultProps = defaultProps;
