import React from 'react';

import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

const propTypes = {
  isHorizontal: PropTypes.bool,
  animated: PropTypes.bool,
  celled: PropTypes.bool,
  inlineHours: PropTypes.bool,
  hourGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  isHorizontal: false,
  animated: false,
  celled: false,
  inlineHours: false,
};

export default function HoursOfOperationDaily(props) {
  const { hourGroups, isHorizontal, animated, celled, inlineHours } = props;

  return (
    <List horizontal={isHorizontal} animated={animated} celled={celled} relaxed>
      {hourGroups.map((hourGroup) => {
        const { dayLabel, hoursValue } = hourGroup;
        return (
          <List.Item key={dayLabel}>
            <List.Content>
              <List.Header as="header" className="h5">
                {dayLabel}
                {inlineHours && `: ${hoursValue.join(' | ')}`}
              </List.Header>
              {!inlineHours && (
                <List.Description>
                  <ul className="unstyled-list">
                    {hoursValue.map((hour) => (
                      <li key={hour}>{hour}</li>
                    ))}
                  </ul>
                </List.Description>
              )}
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
}

HoursOfOperationDaily.propTypes = propTypes;
HoursOfOperationDaily.defaultProps = defaultProps;
