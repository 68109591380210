import React from "react";

import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";

import { HourOfOperationPropType } from "../../LoctionSorted.proptypes";
import CurrentlyOpenLabel from "./CurrentlyOpenLabel";

export default function HoursOfOperationDailyWithPopup({
  displayOpenClosedStatus,
  timezone,
  hours,
  children,
  label,
  secondaryLabel,
}) {
  let visibleFirstDay;
  if (displayOpenClosedStatus && timezone) {
    visibleFirstDay = (
      <div className="hoo-withpopup-first">
        <CurrentlyOpenLabel hours={hours} timezone={timezone} />
        {secondaryLabel}
        <Icon name="caret down" />
      </div>
    );
  } else {
    visibleFirstDay = (
      <div className="hoo-withpopup-first">
        <header className="h4">{label}</header>
        <p className="hoo-hours-paragraph">{secondaryLabel}</p>
        <Icon name="caret down" />
      </div>
    );
  }

  return (
    <Popup
      className="hoo-popup"
      position="bottom left"
      trigger={visibleFirstDay}
      content={children}
      basic
      on="click"
    />
  );
}

HoursOfOperationDailyWithPopup.propTypes = {
  displayOpenClosedStatus: PropTypes.bool.isRequired,
  timezone: PropTypes.string,
  hours: PropTypes.arrayOf(HourOfOperationPropType).isRequired,
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
};

HoursOfOperationDailyWithPopup.defaultProps = {
  timezone: null,
  label: "See hours",
  secondaryLabel: "",
};
