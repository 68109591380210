import React from "react";

import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

import GeoClosestLocation from "./components/GeoClosestLocation";
import Location from "./components/Location";
import { LocationPropType } from "./LoctionSorted.proptypes";
import SearchLocations from "./components/SearchLocations";

import "./LocationsSorted.css";

export default class LocationsSorted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
      startOrderByDistance: false,
    };

    this.applyFilter = this.applyFilter.bind(this);
    this.onStartSortByDistance = this.onStartSortByDistance.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  onStartSortByDistance() {
    this.setState({ startOrderByDistance: true });
  }

  onSearchChange(e) {
    this.setState({ searchQuery: e.target.value });
  }

  applyFilter(locations = []) {
    const { searchQuery } = this.state;

    let filteredLocations = locations;

    if (searchQuery && searchQuery.length) {
      const re = new RegExp(searchQuery, "i");

      filteredLocations = locations.filter((loc) => {
        const keys = Object.keys(loc);
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          if (
            key !== "id" &&
            typeof loc[key] !== "object" &&
            re.test(loc[key])
          ) {
            return true;
          } 
          if(key === 'address') {
            let addressKeys = Object.keys(loc[key])
            let str ='';
            addressKeys.forEach(v => str += loc[key][v] + ' ')
            if(re.test(str)) {
              return true;
            }
          }
        }
        return false;
      });
    }

    return filteredLocations;
  }

  render() {
    const {
      locations,
      searchHeader,
      searchHeaderAs,
      searchPlaceholder,
      verticalAlign,
      horizontalAlign,
      className,
      showIcon,
      distanceUnit,
    } = this.props;
    const { searchQuery, startOrderByDistance } = this.state;
    return (
      <>
        <GeoClosestLocation
          start={startOrderByDistance}
          locations={locations}
          distanceUnit={distanceUnit}
        >
          {({
            loading,
            locations: geoClosestLocations,
            state: geoClosestLocationStatus,
          }) => {
            const filteredLocations = this.applyFilter(geoClosestLocations);
            const noResults = filteredLocations.length === 0;

            return (
              <Grid
                id="location-grid"
                stackable
                className={className}
                verticalAlign={verticalAlign}
                textAlign={horizontalAlign}
              >
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SearchLocations
                      onSearchChange={this.onSearchChange}
                      searchHeader={searchHeader}
                      searchHeaderAs={searchHeaderAs}
                      searchQuery={searchQuery}
                      searchPlaceholder={searchPlaceholder}
                      locationSortingStatus={geoClosestLocationStatus}
                      onSort={this.onStartSortByDistance}
                      distanceLoading={loading}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {noResults && (
                    <p style={{ color: "gray" }}>
                      No results found for the search
                      {`"${searchQuery}".`}
                    </p>
                  )}
                  {filteredLocations.map((loc) => (
                    <Grid.Column
                      id={loc.id}
                      computer={8}
                      tablet={8}
                      mobile={16}
                      className="location-filter-location-detail"
                    >
                      <Location
                        address={{
                          street: loc.street,
                          city: loc.city,
                          state: loc.state,
                          zip: loc.zipCode,
                          country: loc.country,
                        }}
                        timezone={loc.timezone}
                        hours={loc.hours}
                        phone={loc.phoneNumber}
                        as="card"
                        showAddress
                        showIcon={showIcon}
                        showMap={filteredLocations.length === 1}
                        {...loc}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
            );
          }}
        </GeoClosestLocation>
      </>
    );
  }
}

LocationsSorted.propTypes = {
  distanceUnit: PropTypes.oneOf(["M", "K"]),
  searchHeader: PropTypes.string,
  searchHeaderAs: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  className: PropTypes.string,
  locations: PropTypes.arrayOf(LocationPropType).isRequired,
  verticalAlign: PropTypes.string,
  horizontalAlign: PropTypes.string,
  showIcon: PropTypes.bool,
};

LocationsSorted.defaultProps = {
  className: "",
  distanceUnit: "M",
  searchHeader: "Search Our Locations",
  searchHeaderAs: "h2",
  searchPlaceholder: "123 Main Street, Boston MA",
  verticalAlign: "center",
  horizontalAlign: "center",
  showIcon: true,
};
