import React from "react";

import PropTypes from "prop-types";

import ReactGA from "react-ga";

import { ChildrenPropType } from "../../LoctionSorted.proptypes";

export default class OutboundLink extends React.Component {
  constructor(props) {
    super(props);
    this.sendOutboundLinkEvent = this.sendOutboundLinkEvent.bind(this);
  }

  getExtraneousProps() {
    const { to, label, eventLabel, as, newTab, ...rest } = this.props;
    return rest;
  }

  createContainerProps() {
    const { to, newTab, className, eventLabel, as, label, ...rest } =
      this.props;
    const linkProps = newTab
      ? { href: to, target: "_blank", rel: "noopener noreferrer" }
      : { href: to };
    return {
      ...linkProps,
      ...rest,
      className,
      onClick: this.sendOutboundLinkEvent,
    };
  }

  sendOutboundLinkEvent() {
    ReactGA.outboundLink({ label: this.createLabel() }, () => null);
  }

  createLabel() {
    const { label, eventLabel, children, to } = this.props;
    const childrenLabel = typeof children === "string" ? children : null;
    return eventLabel || label || childrenLabel || to;
  }

  render() {
    const { label, as, children } = this.props;
    const elementProps = {
      ...this.createContainerProps(),
      children: children || label,
    };

    return React.createElement(as, elementProps);
  }
}

OutboundLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  eventLabel: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  newTab: PropTypes.bool,
  className: PropTypes.string,
  children: ChildrenPropType,
};

OutboundLink.defaultProps = {
  eventLabel: "",
  label: "",
  as: "a",
  newTab: true,
  className: "",
  children: null,
};
