export const isOldGatsbyImage = (image) => {
  return (
    typeof image !== 'string' &&
    !!image &&
    ((!!image.childImageSharp &&
      (!!image.childImageSharp.fixed || !!image.childImageSharp.fluid)) ||
      !!image.fixed ||
      !!image.fluid)
  );
};

export const getOldImage = (oldImage) => {
  if (!isOldGatsbyImage(oldImage)) {
    throw Error('You must call this method with an old gatsby-image image data');
  }
  return oldImage.childImageSharp ? oldImage.childImageSharp : oldImage;
};

export const isGatsbyImage = (image) => {
  return (
    typeof image !== 'string' &&
    !!image &&
    ((!!image.childImageSharp && !!image.childImageSharp.gatsbyImageData) ||
      !!image.gatsbyImageData)
  );
};

export const isServerSideRendering = () => typeof window === 'undefined';
