import PropTypes from "prop-types";

export const HourOfOperationPropType = PropTypes.shape({
  day: PropTypes.number.isRequired,
  open: PropTypes.number.isRequired,
  close: PropTypes.number.isRequired,
  label: PropTypes.string,
});

export const LocationPropType = PropTypes.shape({
  _id: PropTypes.number,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  hours: PropTypes.arrayOf(HourOfOperationPropType),
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  country: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
});

export const AsPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.elementType,
]);

export const ChildrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.func,
]);

export const AddressPropType = PropTypes.shape({
  street: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string,
  location_name: PropTypes.string,
});
