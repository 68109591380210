import _get from "lodash/get";

import DateUtils from "../../../../utils/date/DateUtils";

export function getHourToDisplay(hourDay, timezone) {
  const { hoursValue = [], hours = [] } = hourDay;
  const now = DateUtils.getTimezoneAwareNow(timezone);

  const openHourIndex = hours.findIndex(({ close }) => {
    const hourClose = DateUtils.parseHourInt(close, { referenceDate: now });
    // only show the first hour set that is not in the past
    return now < hourClose;
  });
  // if all hour sets have passed, show the last one
  const lastIndex = hoursValue.length - 1;
  return openHourIndex >= 0
    ? hoursValue[openHourIndex]
    : _get(hoursValue, `[${lastIndex}]`, "");
}

export function getPopUpLabelForDaily(hourDay, timezone) {
  let label = "";
  let secondaryLabel = "";

  if (!hourDay) {
    label = "See hours";
  } else {
    const { dayLabel = "" } = hourDay;
    label = dayLabel;
    secondaryLabel = getHourToDisplay(hourDay, timezone);
  }
  return [label, secondaryLabel];
}
