import React from "react";

import PropTypes from "prop-types";

import ReactGA from "react-ga";

import { ChildrenPropType } from "../../LoctionSorted.proptypes";

export default class Event extends React.Component {
  formatEventType() {
    const { eventType } = this.props;
    return `on${eventType[0].toUpperCase()}${eventType.substring(1)}`;
  }

  wrapEventHandler() {
    const { children, event } = this.props;
    const formattedEventType = this.formatEventType();
    const childEventHandler = children.props[formattedEventType];
    return {
      [formattedEventType]: () => {
        ReactGA.event(event);

        if (childEventHandler) {
          childEventHandler();
        }
      },
    };
  }

  render() {
    const { children } = this.props;
    return React.cloneElement(children, {
      ...children.props,
      ...this.wrapEventHandler(),
    });
  }
}

Event.propTypes = {
  event: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.number,
    nonInteraction: PropTypes.bool,
  }).isRequired,
  eventType: PropTypes.oneOf(["click", "change"]).isRequired,
  children: ChildrenPropType.isRequired,
};

Event.defaultProps = {};
