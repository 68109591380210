import React from "react";

import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import { HourOfOperationPropType } from "../../LoctionSorted.proptypes";
import IconElement from "../IconElement";
import {
  getHourToDisplay,
  getPopUpLabelForDaily,
} from "./HoursOfOperation.utils";
import HoursOfOperationDaily from "./HoursOfOperationDaily";
import HoursOfOperationLabeled from "./HoursOfOperationLabeled";
import HoursOfOperationModal from "./HoursOfOperationModal";
import HoursOfOperationDailyWithPopup from "./HoursOfOperationWithPopup";
import {
  groupByDay,
  groupByDayAndTime,
  groupByLabel,
  groupByDayLabel,
  NO_LABEL,
  HOURS_LABELS,
  getHourLabel,
  allDayHoursAreUndefined,
  getHourGroups,
} from "./utils";

import "./HoursOfOperation.css";

export default class HoursOfOperation extends React.Component {
  constructor(props) {
    super(props);
    this.createLabelForDays = this.createLabelForDays.bind(this);
  }

  getLabeledComponentAndTodaysHour() {
    const {
      firstGroupLevel,
      hours,
      displayOption,
      startWithToday,
      timezone,
      orientation,
      animated,
      celled,
      inlineHours,
      startDay,
    } = this.props;

    const isHorizontal = orientation === "horizontal";
    let labelGroups = null;
    let todaysHourOfOperation = null;

    if (firstGroupLevel === "label") {
      [labelGroups, todaysHourOfOperation] = groupByLabel(
        hours,
        this.createLabelForDays,
        displayOption,
        startWithToday,
        timezone,
        startDay
      );
    } else {
      [labelGroups, todaysHourOfOperation] = groupByDayLabel(
        hours,
        this.createLabelForDays,
        displayOption,
        startWithToday,
        timezone,
        startDay
      );
    }

    const component = (
      <HoursOfOperationLabeled
        data={labelGroups}
        isHorizontal={isHorizontal}
        animated={animated}
        celled={celled}
        inlineHours={inlineHours}
      />
    );

    return [component, todaysHourOfOperation];
  }

  getDailyComponentAndTodaysHour() {
    const {
      hours,
      displayOption,
      startWithToday,
      timezone,
      orientation,
      animated,
      celled,
      inlineHours,
      startDay,
    } = this.props;

    const isHorizontal = orientation === "horizontal";

    const groupedHourGroups =
      displayOption === "grouped"
        ? groupByDayAndTime(hours, this.createLabelForDays)
        : groupByDay(hours, this.createLabelForDays);

    const [todayFirstInHoursGroup, hourGroups] = getHourGroups(
      groupedHourGroups,
      timezone,
      startWithToday,
      startDay
    );

    const component = (
      <HoursOfOperationDaily
        hourGroups={hourGroups}
        isHorizontal={isHorizontal}
        animated={animated}
        celled={celled}
        inlineHours={inlineHours}
      />
    );

    const todaysHourOfOperation = todayFirstInHoursGroup[0];
    return [component, todaysHourOfOperation];
  }

  getPopUpLabelForLabeled(hourDay) {
    const { firstGroupLevel, timezone } = this.props;

    let label = "";
    let secondaryLabel = "";
    if (firstGroupLevel === "day") {
      [label, secondaryLabel] = getPopUpLabelForDaily(hourDay, timezone);
    }
    if (firstGroupLevel === "label") {
      label = hourDay.mainLabel === NO_LABEL ? "See hours" : hourDay.mainLabel;
      secondaryLabel = getHourToDisplay(hourDay);
    }

    return [label, secondaryLabel];
  }

  formatLabel(label) {
    const { capitalizeLabels, truncateLabels } = this.props;
    const displayedLabel = truncateLabels ? label.slice(0, 3) : label;
    return capitalizeLabels ? displayedLabel.toUpperCase() : displayedLabel;
  }

  createLabelForDays(firstDay, secondDay = null) {
    const firstLabel = this.formatLabel(HOURS_LABELS[firstDay]);

    if (secondDay !== null) {
      const secondLabel = this.formatLabel(HOURS_LABELS[secondDay]);
      return `${firstLabel} - ${secondLabel}`;
    }

    return firstLabel;
  }

  render() {
    const {
      className,
      showIcon,
      displayOption,
      iconAs,
      hours,
      timezone,
      displayOpenClosedStatus,
      header,
      subheader,
    } = this.props;

    const joinedClassName = ["hours-of-operation", className].join(" ").trim();

    if (allDayHoursAreUndefined(hours)) {
      return null;
    }

    const hoursHaveLabels = hours.some((hour) => !!getHourLabel(hour));

    let mainComponent = null;
    let todaysHourOfOperation = null;
    let mainPopupLabel = null;
    let secondaryPopupLabel = null;

    if (hoursHaveLabels) {
      [mainComponent, todaysHourOfOperation] =
        this.getLabeledComponentAndTodaysHour();
      [mainPopupLabel, secondaryPopupLabel] = this.getPopUpLabelForLabeled(
        todaysHourOfOperation
      );
    } else {
      [mainComponent, todaysHourOfOperation] =
        this.getDailyComponentAndTodaysHour();
      [mainPopupLabel, secondaryPopupLabel] = getPopUpLabelForDaily(
        todaysHourOfOperation,
        timezone
      );
    }

    if (displayOption === "dailyWithPopup") {
      return (
        <HoursOfOperationDailyWithPopup
          displayOpenClosedStatus={displayOpenClosedStatus}
          timezone={timezone}
          hours={todaysHourOfOperation.hours}
          label={mainPopupLabel}
          secondaryLabel={secondaryPopupLabel}
        >
          {mainComponent}
        </HoursOfOperationDailyWithPopup>
      );
    }

    const mainComponentWithHeaders = (
      <div className={joinedClassName}>
        {header && (
          <Header as="header" className="h2">
            {header}
          </Header>
        )}
        {subheader && (
          <Header as="header" className="h3">
            {subheader}
          </Header>
        )}
        {mainComponent}
      </div>
    );

    if (displayOption === "modal") {
      return (
        <HoursOfOperationModal iconAs={iconAs}>
          {mainComponentWithHeaders}
        </HoursOfOperationModal>
      );
    }

    return showIcon ? (
      <IconElement
        iconType="clock"
        element={mainComponentWithHeaders}
        iconPosition="top"
      />
    ) : (
      mainComponentWithHeaders
    );
  }
}

HoursOfOperation.propTypes = {
  hours: PropTypes.arrayOf(HourOfOperationPropType).isRequired,
  animated: PropTypes.bool,
  capitalizeLabels: PropTypes.bool,
  celled: PropTypes.bool,
  className: PropTypes.string,
  displayOption: PropTypes.oneOf([
    "daily",
    "dailyWithPopup",
    "grouped",
    "modal",
  ]),
  displayOpenClosedStatus: PropTypes.bool,
  startWithToday: PropTypes.bool,
  header: PropTypes.string,
  inlineHours: PropTypes.bool,
  iconAs: PropTypes.string,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  showIcon: PropTypes.bool,
  subheader: PropTypes.string,
  timezone: PropTypes.string,
  truncateLabels: PropTypes.bool,
  firstGroupLevel: PropTypes.oneOf(["label", "day"]),
  startDay: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7]),
};

HoursOfOperation.defaultProps = {
  animated: false,
  capitalizeLabels: false,
  celled: false,
  className: "",
  displayOption: "daily",
  displayOpenClosedStatus: false,
  startWithToday: false,
  header: "",
  inlineHours: false,
  iconAs: "icon",
  orientation: "vertical",
  showIcon: false,
  subheader: "",
  timezone: null,
  truncateLabels: false,
  firstGroupLevel: "day",
  startDay: 1, // Monday
};

HoursOfOperation.propOptions = {
  header: ["Hours of Operation", "Our Hours", ""],
};

HoursOfOperation.dataPropsMap = {
  hours: ["business", "primary_location", "hours"],
};
